<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    @click="openAddDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Add'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <v-btn text @click="openFilterExpansion">
                      <v-icon>{{ openFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">{{ item.name }}</td>
                <td>{{ item.link.replace("/c", "") }}</td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue-grey darken-1"
                        icon
                        @click="openModuleDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-folder-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Module</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Add, View, Edit Employee Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : dialogType === 'Module'
          ? 'blue-grey darken-1'
          : ''
      "
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : dialogType === 'Module'
          ? 'blue-grey darken-1'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? validateAddDialog()
          : dialogType === 'Edit'
          ? validateEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : dialogType === 'Module'
          ? closeModuleDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            :md="dialogType === 'Module' ? 2 : 3"
            :lg="dialogType === 'Module' ? 2 : 3"
            :xl="dialogType === 'Module' ? 2 : 3"
          ></v-col>
          <v-col
            cols="12"
            sm="12"
            :md="dialogType === 'Module' ? 8 : 6"
            :lg="dialogType === 'Module' ? 8 : 6"
            :xl="dialogType === 'Module' ? 8 : 6"
          >
            <details-container
              v-if="dialogType !== 'Module'"
              :data="data"
              :readonly="readonly"
              ref="detailsContainer"
              @openSaveDialog="
                dialogType === 'Add'
                  ? openSaveAddDialog()
                  : dialogType === 'Edit'
                  ? openSaveEditDialog()
                  : ''
              "
            ></details-container>
            <v-container v-if="dialogType === 'Module'" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="pt-0">
                  <v-card class="elevation-0">
                    <v-card-title class="pa-4">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          lg="3"
                          xl="3"
                          offset-md="9"
                          offset-lg="9"
                          offset-xl="9"
                          order="first"
                          order-sm="first"
                          order-md="last"
                          order-lg="last"
                          order-xl="last"
                        >
                          <div class="float-right">
                            <fab-button
                              @click="openAddModuleDialog()"
                              dark
                              top
                              xsmall
                              :icon="'mdi-plus'"
                              :tooltipSpan="'Add'"
                            ></fab-button>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                  <v-divider></v-divider>
                  <v-data-table
                    :headers="modulesHeader"
                    :items="modulesArray"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
                    :server-items-length="modulesArray.length"
                  >
                    <template v-if="modulesArray.length === 0" v-slot:no-data>
                      <v-container>
                        <v-row>
                          <v-col md="6" offset-md="3">
                            <span class="title font-weight-bold"
                              >NO DATA AVAILABLE.</span
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-else v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-uppercase">{{ item.name }}</td>
                          <td>{{ item.code }}</td>
                          <td class="text-center text-no-wrap">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="teal"
                                  icon
                                  @click="openEditModuleDialog(item)"
                                  v-on="on"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="red"
                                  icon
                                  @click="openDeleteModuleDialog(item)"
                                  v-on="on"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            :md="dialogType === 'Module' ? 2 : 3"
            :lg="dialogType === 'Module' ? 2 : 3"
            :xl="dialogType === 'Module' ? 2 : 3"
          ></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Adding Page/s ---->

    <mini-dialog
      :dialog="saveAddDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddDialog()"
      @confirmDialog="addPage()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Editing Page/s ---->

    <mini-dialog
      :dialog="saveEditDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditDialog()"
      @confirmDialog="editPage()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Delete Page/s ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deletePage()"
    ></mini-dialog>

    <!----- Here is the Add Module Dialog ----->

    <mini-dialog
      :dialog="addModuleDialog"
      :max-width="'620px'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Add Module'"
      :cardText="''"
      @closeDialog="closeAddModuleDialog()"
      @confirmDialog="openSaveAddModuleDialog()"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <v-form ref="formAddModule">
            <v-row>
              <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
              <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                <v-text-field
                  v-model="moduleData.name"
                  label="Name"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="moduleData.code"
                  label="Code"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </mini-dialog>

    <!---- Here is the Confirmation of Add Module ---->

    <mini-dialog
      :dialog="saveAddModuleDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddModuleDialog()"
      @confirmDialog="addModule()"
    ></mini-dialog>

    <!----- Here is the Edit Module Dialog ----->

    <mini-dialog
      :dialog="editModuleDialog"
      :max-width="'620px'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Edit Module'"
      :cardText="''"
      @closeDialog="closeEditModuleDialog()"
      @confirmDialog="openSaveEditModuleDialog()"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <v-form ref="formEditModule">
            <v-row>
              <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
              <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                <v-text-field
                  v-model="moduleData.name"
                  label="Name"
                  required
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="moduleData.code"
                  label="Code"
                  required
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </mini-dialog>

    <!---- Here is the Confirmation of Edit Module ---->

    <mini-dialog
      :dialog="saveEditModuleDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditModuleDialog()"
      @confirmDialog="editModule()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Delete Module/s ---->

    <mini-dialog
      :dialog="deleteModuleDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      @closeDialog="closeDeleteModuleDialog()"
      @confirmDialog="deleteModule()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/CompanyServices";
import DetailsContainer from "@/components/details_container/PageDetailsContainer";

export default {
  components: {
    DetailsContainer,
  },
  data: () => {
    return {
      title: "Page",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Name", sortable: false },
        { text: "Link", sortable: false },
        { text: "Actions", align: "center", sortable: false },
      ],
      items: [],
      data: {},
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      fullWidthDialog: false,
      fullWidthDialogLoading: false,
      dialogType: "",
      dialogColor: "",
      saveButton: false,
      readonly: false,
      saveAddDialog: false,
      saveEditDialog: false,
      deleteDialog: false,
      modulesArray: [],
      modulesHeader: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Name", sortable: false },
        { text: "Code", sortable: false },
        { text: "Actions", align: "center", sortable: false },
      ],
      moduleData: {},
      addModuleDialog: false,
      saveAddModuleDialog: false,
      editModuleDialog: false,
      saveEditModuleDialog: false,
      deleteModuleDialog: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        let response = (await Services.getPages(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.saveButton = true;
      this.readonly = false;

      this.data.name = "";
      this.data.link = "";
      this.data.sequence = "";

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
    },
    validateAddDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveAddDialog() {
      this.saveAddDialog = true;
    },
    closeSaveAddDialog() {
      this.saveAddDialog = false;
    },
    async addPage() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        name: data.name,
        link: data.link,
        sequence: data.sequence,
      };

      try {
        const response = await Services.addPage(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openViewDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.saveButton = false;
      this.readonly = true;

      const returnedPage = await this.setPage(item.id);

      this.data.name = returnedPage.name;
      this.data.link = returnedPage.link;
      this.data.sequence = returnedPage.sequence;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
    },
    async openEditDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.saveButton = true;
      this.readonly = false;

      const returnedPage = await this.setPage(item.id);

      this.data.id = item.id;
      this.data.name = returnedPage.name;
      this.data.link = returnedPage.link;
      this.data.sequence = returnedPage.sequence;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
    },
    validateEditDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveEditDialog() {
      this.saveEditDialog = true;
    },
    closeSaveEditDialog() {
      this.saveEditDialog = false;
    },
    async editPage() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        name: data.name,
        link: data.link,
        sequence: data.sequence,
      };

      try {
        const response = await Services.editPage(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async openModuleDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Module";
      this.saveButton = false;
      this.readonly = false;
      this.data.id = item.id;

      this.modulesArray = await this.setModules(item.id);

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeModuleDialog() {
      this.fullWidthDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deletePage() {
      this.confirmLoading = true;

      try {
        const response = await Services.deletePage(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openAddModuleDialog() {
      this.addModuleDialog = true;

      this.moduleData = {};
    },
    closeAddModuleDialog() {
      this.addModuleDialog = false;
    },
    openSaveAddModuleDialog() {
      if (this.$refs.formAddModule.validate()) {
        this.saveAddModuleDialog = true;
      }
    },
    closeSaveAddModuleDialog() {
      this.saveAddModuleDialog = false;
    },
    async addModule() {
      this.confirmLoading = true;

      const body = {
        name: this.moduleData.name,
        code: this.moduleData.code,
        page_id: this.data.id,
      };

      try {
        const response = await Services.addModule(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAddModuleDialog();
          this.closeSaveAddModuleDialog();

          this.modulesArray.length = 0;

          this.modulesArray = await this.setModules(this.data.id);

          this.$infoBar({
            type: "success",
            text: "Module has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openEditModuleDialog(item) {
      this.editModuleDialog = true;

      this.moduleData.id = item.id;
      this.moduleData.name = item.name;
      this.moduleData.code = item.code;
    },
    closeEditModuleDialog() {
      this.editModuleDialog = false;
    },
    openSaveEditModuleDialog() {
      if (this.$refs.formEditModule.validate()) {
        this.saveEditModuleDialog = true;
      }
    },
    closeSaveEditModuleDialog() {
      this.saveEditModuleDialog = false;
    },
    async editModule() {
      this.confirmLoading = true;

      const body = {
        name: this.moduleData.name,
        code: this.moduleData.code,
      };

      try {
        const response = await Services.editModule(body, this.moduleData.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeEditModuleDialog();
          this.closeSaveEditModuleDialog();

          this.modulesArray.length = 0;

          this.modulesArray = await this.setModules(this.data.id);

          this.$infoBar({
            type: "success",
            text: "Module has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openDeleteModuleDialog(item) {
      this.deleteModuleDialog = true;
      this.moduleData.id = item.id;
    },
    closeDeleteModuleDialog() {
      this.deleteModuleDialog = false;
    },
    async deleteModule() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteModule(this.moduleData.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeDeleteModuleDialog();

          this.modulesArray.length = 0;

          this.modulesArray = await this.setModules(this.data.id);

          this.$infoBar({
            type: "success",
            text: "Module has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setPage(id) {
      const response = await Services.getPage(id);

      return response.data;
    },
    async setModules(id) {
      const response = await Services.getModules({
        page_id: id,
      });

      return response.data.data;
    },
    closeAllDialog() {
      this.closeAddDialog();
      this.closeSaveAddDialog();
      this.closeViewDialog();
      this.closeEditDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style></style>
